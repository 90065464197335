
import type { PropType } from 'vue'
import { defineComponent, nextTick, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { domainRegexp, removeProtocolFromUrl } from '@/services/utils'

export default defineComponent({
  components: {
    TmButton,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Add domain',
    },
    isFocused: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const indexForFocus = ref<number>()
    const fieldRefs = ref<any[]>([])

    const addAnother = () => {
      emit('update:modelValue', [...props.modelValue, ''])
      nextTick(() => focus())
    }

    const setFirstEmptyValue = () => {
      emit('update:modelValue', [''])
    }

    const deleteItem = (deleteIndex: number) => {
      if (props.modelValue?.length > 1) {
        emit('update:modelValue', props.modelValue?.filter((item, index) => index !== deleteIndex))
      } else {
        setFirstEmptyValue()
      }
    }

    const checkFieldValue = (fieldIndex: number) => {
      props.modelValue[fieldIndex] = removeProtocolFromUrl(props.modelValue[fieldIndex])
    }

    const blur = () => {
      if (fieldRefs.value.length) {
        fieldRefs.value[fieldRefs.value.length - 1]?.blur()
      }
    }
    const focus = (index?: number) => {
      if (fieldRefs.value.length) {
        fieldRefs.value[index === undefined ? fieldRefs.value.length - 1 : index]?.focus()
      }
    }

    watch(
      () => props.isFocused,
      async (newValue) => {
        if (newValue) {
          if (!props.modelValue?.length) {
            setFirstEmptyValue()
          }
          await nextTick()
          focus(indexForFocus.value)
        } else {
          emit('update:modelValue', props.modelValue?.filter((item) => !!item))
          indexForFocus.value = undefined
          await nextTick()
          fieldRefs.value = []
        }
      })

    return {
      indexForFocus,
      fieldRefs,
      domainRegexp,
      addAnother,
      deleteItem,
      checkFieldValue,
      blur,
      focus,
    }
  },
})
