
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import countryCodes from '@/definitions/_general/_data/contryPhoneCodes'
import TmFieldContainerRemoveBtn from '@/components/shared/tmFieldsContainer/TmFieldContainerRemoveBtn.vue'

const contactsTypePhone = ['Mobile', 'Work', 'Personal', 'Other']
const getEmptyField = () => ({ phone: '', type: contactsTypePhone[0] })
export default defineComponent({
  components: {
    TmFieldContainerRemoveBtn,
    TmTooltip,
    TmFlag,
    TmButton,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array as PropType<{phone: string, type: string}[]>,
      default: () => [getEmptyField()],
    },
    placeholder: {
      type: String,
      default: 'Add social media links',
    },
    isFocused: {
      type: Boolean,
    },
    leftLabel: {
      type: Boolean,
    },
    onePhone: {
      type: Boolean,
    },
    size: {
      type: String as PropType<'' | 'large'>,
    },
    hideDeleteButton: {
      type: Boolean,
    },
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const fieldRef = ref()
    const errorHintRef = ref([])
    const focusedItem = ref(0)
    const isEmptyFields = computed(() => !props.isFocused && props.modelValue.length === 1 && !props.modelValue[0].phone)
    const isSizeLarge = computed(() => props.size === 'large')

    const findCountry = (phoneNumber: string): {name: string, id: string, code: string} | undefined =>
      (countryCodes.find(({ country }) => phoneNumber.startsWith(country.code)) || countryCodes[0]).country

    const setFocusedItem = (index: number) => {
      focusedItem.value = index
    }
    setFocusedItem(props.modelValue.length - 1)

    const addAnother = () => {
      emit('update:modelValue', [...props.modelValue, getEmptyField()])
      setFocusedItem(props.modelValue.length)
    }

    const setFirstEmptyValue = () => {
      emit('update:modelValue', [getEmptyField()])
    }

    if (!props.modelValue.length) {
      setFirstEmptyValue()
    }

    const deleteItem = (deleteIndex: number) => {
      if (props.modelValue?.length > 1) {
        emit('update:modelValue', props.modelValue?.filter((item, index) => index !== deleteIndex))
        setFocusedItem(props.modelValue.length - 2)
      } else {
        setFirstEmptyValue()
      }
    }

    watch(
      () => props.isFocused,
      (newValue) => {
        if (newValue && !props.modelValue?.length) {
          setFirstEmptyValue()
        }
      })

    return {
      isSizeLarge,
      fieldRef,
      errorHintRef,
      isEmptyFields,
      focusedItem,
      findCountry,
      addAnother,
      deleteItem,
      setFocusedItem,
      contactsTypePhone,
    }
  },
})
